.main-frame {
    height: 100%;
}

.main-dashboard {
    padding: 0rem 2.5rem;
}

.user-greeting {
    margin: 1rem 0.5rem;
}

.welcome {
    color: rgba(62, 67, 93, 0.80);
    font-family: Urbanist;
    font-size: 1.75rem;
    font-weight: 700;
}

.good-day {
    color: rgba(0, 0, 0, 0.37);
    font-family: Montserrat;
    font-weight: 500;
}

.marketing-img {
    width: 40rem;
}

.course-headings {
    position: relative;
    margin: 2rem 0.5rem 1.5rem 0.5rem;
}

.mycourses {
    opacity: 0.5;
    color: var(--Black, #000);
    font-family: Montserrat;
    font-size: 1.125rem;
    font-weight: 600;
    letter-spacing: 0.0225rem;
}

.view-all {
    position: absolute;
    right: 0rem;
    color: #7F56D9;
    font-family: Montserrat;
    font-weight: 500;
    letter-spacing: 0.02rem;
}

.course-container {
    display: flex;
    justify-content: space-evenly;
}

.course-list-item {
    position: relative;
    padding: 1rem;
    border-radius: 0.875rem;
    background: var(--White, #FFF);
    width: 16rem;
    height: 14rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin: 0rem 0rem 2.5rem 0rem;
}

.course-strip {
    width: 16rem;
    position: relative;
    bottom: 1rem;
}

.course-label {
    width: 100%;
    font-family: Urbanist;
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0.5rem;
}

.course-info {
    color: var(--Black, #000);
    font-family: Montserrat;
    font-size: 0.875rem;
    font-weight: 500;
    opacity: 0.6;
    margin: 0rem 0rem 1.5rem 0rem;
}

.course-icons {
    display: flex;
    justify-content: space-between;
    width: 90%;
    bottom: 1.5rem;
    position: absolute;
}

.course-instructor {
    color: var(--Black, #000);
    font-family: Montserrat;
    font-size: 0.75rem;
    font-weight: 500;
    opacity: 0.4;
}

.calendar-section {
    background: var(--White, #FFF);
}

.header-panel {
    position: relative;
}

.header-elements {
    position: absolute;
    right: 1.5rem;
    top: 1rem;
    margin: 1rem 0.25rem;
    display: flex;
    align-items: baseline;
}

.notifications-icon {
    position: relative;
    right: 1.5rem;
    top: 0.5rem;
}

.profile-dropdown {
    border-radius: 0.375rem;
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
}

.profile-options {
    right: 0.15rem;
    top: 3rem;
    position: absolute;
    width: 12rem;
    z-index: 1;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    box-shadow: 0px 4px 60.6px 0px rgba(0, 0, 0, 0.21);
    background: var(--background-paper-elevation-8, #FFF);
}

.profile-options ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.header-list {
    font-family: Roboto;
    letter-spacing: 0.00938rem;
}

.list-item {
    cursor: pointer;
    padding: 0.5rem 0rem;
}

.profile-email {
    color: var(--text-secondary, rgba(0, 0, 0, 0.60));
    font-size: 0.85rem;
}

.profile-icons {
    margin: 0rem 0.5rem 0rem 0rem;
}

.calendar-container {
    margin: 4.5rem 0rem 0rem 0rem;
    width:100%;
}

.calendar-container .Mui-selected {
    background-color: #7F56D9 !important;
    color: white !important;
}

.calendar-container-class .Mui-selected {
    background-color: #7F56D9 !important;
    color: white !important;
}

.MuiDateCalendar-root{
    width:100%!important
}

.upcoming-class-section {
    width:100%;
}

.upcoming-class {
    margin: 1rem;
    color: rgba(0, 0, 0, 0.50);
    font-family: Urbanist;
    font-size: 1.125rem;
    font-weight: 600;
}

.class-schedule {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 0.875rem;
    background: #F9F9F9;
    height: 4.375rem;
    margin: 1rem;
}

.course-detail {
    display: flex;
    margin: 0.5rem 0rem 0rem 0rem;
    font-family: Urbanist;
    font-size: 1.125rem;
    font-weight: 600;
}

.point-cursor {
    cursor: pointer;
}

.bullet-point {
    text-align: center;
}

.course-time {
    font-family: Inter;
    font-size: 0.8125rem;
}

.arrow-icon {
    opacity: 0.3;
    position: relative;
    left: 1rem;
}

.modal-25w {
    width: 25rem;
}

.cancel-heading {
    font-size: 0.90rem;
    position: relative;
    right: 0.5rem;
}

.slot-card {
    width: 10rem;
    height: 5.5rem;
    margin: 1rem;
    cursor:pointer;
}

.selected-card {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);   
}

.slot-date {
    font-size: 13px;
}

.current-slot {
    font-size: 9px;
    color: grey;
}

.join-class {
    width: 100%;
    border-radius: 4px;
    border: solid 1px #8931b1 !important;
    background-color: #8931b1 !important;
}

.profile-form-fields {
    margin: 0rem 0rem 1.5rem 0rem !important;
    width: 100% !important;
}

.flex{
    display: flex;
}

.cancel-class {
    width: 100%;
    border-radius: 4px;
    border: solid 1px rgba(0, 0, 0, 0.3) !important;
    background-color: white !important;
    color: black !important;
    margin: 0rem 0rem 0rem 1rem;
}

.modal-90w {
    width: 90rem;
}

.uploadimage {
    width: 10.75rem;
    height: 10.75rem;
    border-radius: 1.125rem;
}

.change-photo {
    color: #7F56D9;
    text-align: center;
    font-family: Inter;
    font-size: 0.80469rem;
    font-weight: 700;
    letter-spacing: -0.02013rem;
    cursor: pointer;
    margin: 1rem;
}



.action-buttons {
    display: flex;
    width: 16rem;
    justify-content: space-between;
    margin: 0rem 1rem;
}

.student-action-buttons .action-buttons {
    margin: 0rem!important;
}

.cancel-profile {
    border: 1px solid #0000004D!important;
    width: 7.5rem;
    background-color: white!important;
    color: #000000B2!important;
}

.save-profile {
    border: 1px solid #8931B1!important;
    width: 7.5rem;
    background-color: #8931B1!important;
    color: white!important
}

@media (max-width: 1024px) {

    .header-panel {
        display: none
    }
    .main-frame-mobile {
        flex-direction: row!important
    }
    
    .calendar-section{
        display: flex;
        width:100%
    }

    .calendar-container{
        width:50%;
        margin: 0rem 1.5rem;
    }

    .upcoming-class, .course-detail {
        font-size:0.95rem;
    }



    .upcoming-class-section {
        margin: 1rem 0rem;
    }
    /* Greeting Section */
    .user-greeting {
        margin: 1rem 0.5rem;
    }

    .welcome {
        font-size: 1.2rem;
    }

    .good-day {
        font-size: 0.8rem;
    }

    /* Remove marketing image on mobile */
    .marketing-img {
        display: none;
    }

    /* Course Headings */
    .course-headings {
        margin: 1.5rem 0.5rem;
    }

    .mycourses {
        font-size: 1rem;
    }

    .view-all {
        font-size: 0.875rem;
    }

    /* Course Container - Stack items vertically */
    .course-container {
        flex-direction: column;
        align-items: center;
    }

    /* Course List Item */
    .course-list-item {
        width: 100%; /* Full width on mobile */
        padding: 0.75rem;
        margin: 0.5rem 0;
    }

    .course-label {
        font-size: 1rem;
    }

    .course-info {
        font-size: 0.75rem;
    }

    /* Action Buttons - Stack vertically */
    .action-buttons {
        flex-direction: column;
        width: 100%;
        gap: 0.5rem;
        margin: 1rem 0;
    }

    .cancel-profile,
    .save-profile {
        width: 100%; /* Full width on mobile */
    }

}

@media (max-width: 480px) {
    .calendar-section {
        flex-direction: column;
    }
    .calendar-container {
        width:100%;
        margin: 0rem;
    }

}